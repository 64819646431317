import { BuyButtonContent } from './BuyButtonContent';
import { extractUrlParameters } from './urlUtils';
import { ResponseStatus, useProductDetails } from './useProductDetails';

export const PRODUCT_ID_HINT =
    'Please provide a URL parameter "productId" with the id of the related Product in the URL';
export const LEVEL_HINT = 'You have selected a multilevel product. Please add the "level" parameter in the URL';

interface Props {
    userLocale: string;
}

export const BuyButtonWrapper = (props: Props) => {
    const { userLocale } = props;

    const { productId, level } = extractUrlParameters(window.location.href);

    const { product, status } = useProductDetails({ productId, level });
    if (!productId) {
        return <div>{PRODUCT_ID_HINT}</div>;
    }

    if (status === ResponseStatus.missing_level) {
        return <div>{LEVEL_HINT}</div>;
    }

    const showPrice = status === 'success' && !!product && product.bookable;
    const enableButton = ResponseStatus.success === status && product?.bookable;

    return (
        <BuyButtonContent
            userLocale={userLocale}
            showPrice={showPrice}
            disableButton={!enableButton}
            product={product}
        />
    );
};
